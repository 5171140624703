<template>
  <div>
    <Menu :menu="filteredMenu" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'
import { SELLERS_MENU } from '@/constants/menu'
import {
  ADDSELLERS_ADMIN_ROUTES,
} from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
  },
  computed: {
    ...mapGetters({
      filterAccessSidebarList: 'filterAccessSidebarList',
      isOwner: 'isOwner',
    }),
    accessSellersRoutes() {
      return this.filterAccessSidebarList(ADDSELLERS_ADMIN_ROUTES)
    },
    filteredMenu() {
      return SELLERS_MENU.filter(item => {
        return this.accessSellersRoutes.includes(this.getRouteKeyByLink(item.link));
      });
    }
  },
  methods: {
    getRouteKeyByLink(link) {
      for (let key in ADDSELLERS_ADMIN_ROUTES) {
        if (ADDSELLERS_ADMIN_ROUTES[key] === link) {
          return key;
        }
      }
      return null;
    }
  }
};
</script>
