import { ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'

export const SELLERS_MENU = [
  { title: 'Настройки', link: ADDSELLERS_ADMIN_ROUTES.SETTINGS_INDEX },
  {
    title: 'Реквизиты клиентов',
    link: ADDSELLERS_ADMIN_ROUTES.CLIENT_REQUESITES_INDEX,
  },
  { title: 'Заказы', link: ADDSELLERS_ADMIN_ROUTES.ORDERS_INDEX },
  { title: 'Дилеры', link: ADDSELLERS_ADMIN_ROUTES.DEALERS_INDEX },
  {
    title: 'Загрузка файлов',
    link: ADDSELLERS_ADMIN_ROUTES.DOWNLOADS_INDEX,
  },
  { title: 'Акции', link: ADDSELLERS_ADMIN_ROUTES.OFFERS_INDEX },
  { title: 'Поставщики', link: ADDSELLERS_ADMIN_ROUTES.SUPPLIERS_INDEX },
  {
    title: 'Главный каталог',
    link: ADDSELLERS_ADMIN_ROUTES.PRODUCTS_INDEX,
  },
  { title: 'Каталоги', link: ADDSELLERS_ADMIN_ROUTES.CATALOGUE_INDEX },
  { title: 'Чаты', link: ADDSELLERS_ADMIN_ROUTES.CHATS_INDEX },
  { title: 'Шаблоны', link: ADDSELLERS_ADMIN_ROUTES.TEMPLATE_INDEX },
  {
    title: 'Марк. уведомления',
    link: ADDSELLERS_ADMIN_ROUTES.MARKETING_NOTIFICATIONS_INDEX,
  },
  { title: 'Видеоматериалы', link: ADDSELLERS_ADMIN_ROUTES.VIDEOS_INDEX },
  {
    title: 'Подписки',
    link: ADDSELLERS_ADMIN_ROUTES.SUBSCRIPTIONS_INDEX,
  },
  { title: 'Заявки', link: ADDSELLERS_ADMIN_ROUTES.FEEDBACKS_INDEX },
  {
    title: 'Список цен. политик',
    link: ADDSELLERS_ADMIN_ROUTES.PRICE_POLICY_INDEX,
  },
  { title: 'Бренды', link: ADDSELLERS_ADMIN_ROUTES.BRANDS_INDEX },
  {
    title: 'Бонусная программа',
    link: ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM_INDEX,
  },
  { title: 'Контент и SEO', link: ADDSELLERS_ADMIN_ROUTES.CONTENT_INDEX },
]
